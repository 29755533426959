import React from 'react'
import { navigateTo } from 'gatsby-link'
import Img from "gatsby-image"
import { graphql } from "gatsby"

import Home from '../../../assets/img/home.jpg'

import PourquoiIrriglobemp4 from '../../../assets/video/Irriglobe_Corpo.mp4'
import PourquoiIrriglobewebm from '../../../assets/video/Irriglobe_Corpo.webm'

import {
  Card,
  CardBody,
  Container,
  Col,
  Row
} from 'reactstrap'

import {
  Button,
} from '../../../components'

import SEO from '../../../components/seo'

const schemaOrg = `{
  "@context": "http://schema.org/",
  "@type": "Service",
  "serviceType": "Services Irrigation",
  "provider": {
    "@type": "LocalBusiness",
    "name": "Irriglobe Irrigation",
    "address": {
      "@type": "PostalAddress",
      "addressLocality": "Montréal",
      "addressRegion": "QC",
      "streetAddress": "8220 Pascal-Gagnon"
    },
    "description": "Irriblobe se positionne en tant qu'expert dans le domaine des Systèmes d'Irrigation et de l'Arrosage Automatique",
    "telephone": "514-905-6000"
  },
  "areaServed": {
    "@type": "AdministrativeArea",
    "name": "Montreal"
  },
  "areaServed": {
    "@type": "AdministrativeArea",
    "name": "Laval"
  },
  "hasOfferCatalog": {
    "@type": "OfferCatalog",
    "name": "Services Irrigation",
    "itemListElement": [
      {
        "@type": "OfferCatalog",
        "name": "Types services irrigation",
        "itemListElement": [
          {
            "@type": "Offer",
            "itemOffered": {
              "@type": "Service",
              "name": "Irrigation résidentielle"
            }
          },
          {
            "@type": "Offer",
            "itemOffered": {
              "@type": "Service",
              "name": "Irrigation commerciale"
            }
          },
          {
            "@type": "Offer",
            "itemOffered": {
              "@type": "Service",
              "name": "Irrigation municipale"
            }
          },
          {
            "@type": "Offer",
            "itemOffered": {
              "@type": "Service",
              "name": "Irrigation Toîts vert"
            }
          }
        ]
      }
    ]
  }
}`

class expertiseIndex extends React.Component {
  render() {

    const seoData = {
      title: "Expertise en Système d'Irrigation et d'Arrosage Automatique",
      description:
        "Irriblobe se positionne en tant qu'expert dans le domaine des Systèmes d'Irrigation et de l'Arrosage Automatique",
      cover: Home,
      path: '/compagnie/expertises',
      schemaOrg
    }

    const allImages = this.props.data.allImageSharp
    const Maestria = allImages.edges.find(e => e.node.fluid.originalName === 'laureat-maestria.jpg')?.node.fluid
    const ProjetInnovateur = allImages.edges.find(e => e.node.fluid.originalName === 'projet-innovateur.jpg')?.node.fluid
    const watersense = allImages.edges.find(e => e.node.fluid.originalName === 'watersense.png')?.node.fluid
    const aiq = allImages.edges.find(e => e.node.fixed.originalName === 'association-irrigation-quebec.png')?.node.fixed
    const cmmtq = allImages.edges.find(e => e.node.fixed.originalName === 'corporation-des-maitres-mecaniciens-en-tuyauterie-du-quebec.png')?.node.fixed
    const rbq = allImages.edges.find(e => e.node.fixed.originalName === 'regie-du-batiment.png')?.node.fixed
    const re = allImages.edges.find(e => e.node.fixed.originalName === 'reseau-environnement.png')?.node.fixed
    const ccmm = allImages.edges.find(e => e.node.fixed.originalName === 'chambre-de-commerce-du-montreal-metropolitain.png')?.node.fixed

    return (
      <div>
        <SEO seoData={seoData} />
        <Container>
          <Row>
            <Col>
               <video controls style={{ width: '100%' }} autoplay>
                <track default kind="captions"
                  srcLang="fr"
                  src="" />
                <source src={PourquoiIrriglobewebm} type="video/webm" />
                <source src={PourquoiIrriglobemp4} type="video/mp4" />
              </video>
            </Col>
          </Row>
        </Container>
        <Container>
        <Row>
            <Col lg={4} md={5} xs={12}>
              <h3>FORCE, FIABILITÉ ET RÉSULTATS</h3>
              <h2>UNE ENTREPRISE FLEXIBLE</h2>
              <a class="btn-round btn btn-primary btn-lg" href="/services">Voir nos services</a>
            </Col>
            <Col lg={8} md={7} xs={12}>
              <Row>
                <Col>
                  <h4>20 ans d’expérience</h4>
                  <p>
                    Fondée en 2003, Irriglobe Inc a acquis l’expérience nécessaire pour offrir un service professionnel. Tous nos techniciens sont spécialement formés pour vous garantir un service personnalisé hors pair.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h4>Assurance responsabilité civile</h4>
                  <p>
                    N’étant jamais trop protégé, la clientèle d’Irriglobe Inc. peut compter sur notre assurance responsabilité civile, pour une protection allant jusqu’à 2 000 000$.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h4>Service 7 jours</h4>
                  <p>
                    La clientèle privilégiée d’Irriglobe Inc pourra bénéficier de notre service rapide 7 jours par semaine. Étant accessible par téléphone, par courriel et par fax, toute demande de service sera traitée dans de très brefs délais.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h4>Souci environnemental</h4>
                  <p>
                    Dans un souci environnemental, Irriglobe Inc. offre les technologies afin d’optimiser votre système d’irrigation de façon écologique: économiser l’eau (détecteurs de pluie), réduire l’impact environnemental des fertilisants (système de fertilisation automatique), récupérer les eaux usées (système de récupération des eaux de pluie).Consultez nous afin d’obtenir votre propre plan d’action.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h4>Programme d’économie des particuliers:</h4>
                  <p>
                    Irriglobe Inc. a mis sur pied un programme d’économie pour ses clients résidentiels. Optez pour un forfait annuel personnalisé à vos besoins et vous économiserez sur chaque service d’entretien.
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <Container>
          <Card>
            <CardBody>
              <Row>
                <Col className="text-center">
                  <h2>Membres et associations</h2>
                </Col>
              </Row>
              <Row style={{ justifyContent: 'center' }}>
                <Col>
                  <p className="text-center"><Img fixed={rbq} alt="Régie du bâtiment" style={{ margin: 20, width: 120, height: 35 }} /><Img fixed={ccmm} alt="La chambre de commerce du Montréal Métropolitain" style={{ margin: 20, width: 207, height: 39 }} /><Img fixed={aiq} alt="Association Irrigation Québec (AIQ)" style={{ margin: 20, width: 140, height: 42 }} /><Img fixed={cmmtq} alt="Corporation des Maîtres Mécaniciens en Tuyauterie du Québec (CMMTQ)" style={{ margin: 20, opacity: 0.5, width: 140, height: 42 }} /><Img fixed={re} alt="Réseau Environnement" style={{ margin: 20, width: 140, height: 42 }} /></p>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>

        <Container>
          <Row>
            <Col className="text-center">
              <h2>Installateur certifié</h2>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <h5>ACCRÉDITÉ PAR L’ASSOCIATION IRRIGATION QUÉBEC (AIQ)</h5>
              <p>Les entrepreneurs en irrigation membres de l’Association Irrigation Québec (AIQ) sont en mesure de vous garantir la qualité de leurs travaux. Grâce à des normes très rigoureuses, les professionnels certifiés peuvent garantir tous leurs travaux, de la conception à la mise en fonction de votre système d’irrigation.
Seuls les membres de l’AIQ ont toutes les compétences nécessaires pour vous offrir une telle garantie.</p>
            </Col>
            <Col md={6}>
              <h5>Corporation des Maîtres Mécaniciens en Tuyauterie du Québec (CMMTQ)</h5>
              <p>Les membres de la CMMTQ sont des entrepreneurs de construction spécialisés qui œuvrent dans le domaine de la mécanique du bâtiment, plus particulièrement en plomberie et en chauffage. Ces entrepreneurs installent, réparent et entretiennent tous les systèmes qui sont essentiels au bon fonctionnement d'un bâtiment. Leurs connaissances ont été vérifiées par des examens, ils sont titulaires d'une licence d'entrepreneur, ils sont soumis à des règles de conduite professionnelle, ils souscrivent à un cautionnement pour indemniser leurs clients, ils sont informés des nouvelles normes en vigueur, ils bénéficient d'un soutien technique par le personnel qualifié de la CMMTQ et ils ont accès à de la formation continue destinée à accroître leurs compétences et habiletés.</p>
            </Col>
            <Col md={6}>

              <h5>Régie du Bâtiment du Québec (RBQ) / licence #5701-3914-01</h5>
              <p>La RBQ délivre des licences aux entrepreneurs et aux constructeurs-propriétaires. Ces licences leur confèrent un droit d’exercice, après une évaluation de leurs compétences professionnelles et de leur intégrité. Elle s’assure qu’ils détiennent une licence valide et conforme aux types de travaux exécutés. </p>
            </Col>
            <Col md={6}>
              <h5>Réseau Environnement</h5>
              <p>Réseau Environnement est le plus important regroupement de spécialistes en environnement au Québec et représente plus de 2700 membres issus de tous les milieux. Actif depuis plus de 50 ans, il a pour mission de promouvoir les bonnes pratiques et l’innovation en environnement.</p>
            </Col>
          </Row>
        </Container>

        <Container>
          <Row>
            <Col className="text-center">
              <h2>Prix et distinctions</h2>
            </Col>
          </Row>
          <Row style={{marginBottom: 100}}>
            <Col md={6}>
              <h5>Lauréat Maestria 2018</h5>
              <h6>Catégorie : Conception / innovation</h6>
              <p>Les maîtres mécaniciens en tuyauterie jouent un grand rôle dans la réalisation des projets de construction en apportant leur expertise pour supporter les autres intervenants ou en proposant des solutions novatrices qui répondent aux exigences de plus en plus élevées de la clientèle.</p>
              <p>Irriglobe a conçu un projet des plus innovateurs en réalisant un système d’irrigation et de récupération provenant de la condensation produite par les déshumidificateurs du supermarché IGA de l’arrondissement de Saint-Laurent, à Montréal. Une première au Canada, ce projet permet de cultiver des fruits et légumes sur un toit végétalisé.</p>
            </Col>
            <Col md={6}>
              <Img fluid={ProjetInnovateur} alt="Lauréat Projet Innovateur" />
            </Col>
          </Row>

          <Row style={{marginBottom: 100}}>
            <Col md={6}>
              <Img fluid={Maestria} alt="Lauréat Maestria 2016" />
            </Col>
            <Col md={6}>
              <h5>Lauréat Maestria 2016</h5>
              <h6>Catégorie : Conception / innovation</h6>
              <p>Cette catégorie récompense l’innovation, l’originalité et l’audace dans la conception d’un projet adapté aux besoins spécifiques, parfois atypiques, des clients. Intégré dans un projet LEED, le projet d’Irriglobe performe dans la récupération des eaux de pluie. Le système, dédié à un toit vert, intègre des contrôles favorisant l’économie d’électricité et d’eau emmagasinée en empêchant son fonctionnement lors de pluies, en plus de générer des économies en chauffage et en climatisation.</p>
            </Col>
          </Row>

          <Row>
            <Col md={8}>
              <h5>WaterSense</h5>
              <p>Nos contrôleurs certifiés WaterSense sont au moins 30% plus économes en eau que les contrôleurs conventionnels . Dans le cadre du système de certification des maisons, ils sont vérifiés et certifiés pour répondre à la liste de contrôle obligatoire de l'EPA et atteindre les critères d'efficacité de l'eau de l'EPA démontrant que la maison utilisera au moins 30 % moins d'eau qu'une résidence typique. Chaque organisme de certification domestique (HCO) utilise sa propre méthode de certification approuvée par WaterSense (WACM) pour mesurer cela. Lorsqu'elles sont associées à une liste de contrôle obligatoire, les exigences du WACM garantissent qu'une installation WaterSense est à la fois économe en eau et performante. Irriglobe est fier de vous offrir uniquement des contrôleur Wifi avec cette norme par souci de l’environnement.</p>
              <h6>Protègons notre plus importante richesse L’EAU ! </h6>
            </Col>
            <Col md={4}>
              <Img fluid={watersense} alt="WaterSense" />
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default expertiseIndex

export const expertisePageQuery = graphql`
  query expertiseQuery {
    site {
      siteMetadata {
        title
        description
        url: siteUrl
        author
      }
    }
    allImageSharp(filter: {fluid: {originalName: {in: [
      "watersense.png",
      "projet-innovateur.jpg",
      "laureat-maestria.jpg",
      "association-irrigation-quebec.png",
      "corporation-des-maitres-mecaniciens-en-tuyauterie-du-quebec.png",
      "regie-du-batiment.png",
      "reseau-environnement.png",
      "chambre-de-commerce-du-montreal-metropolitain.png"
    ]}}}) {
      edges {
        node {
          fluid {
            originalName
            ...GatsbyImageSharpFluid_withWebp
          }
          fixed {
            originalName
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  }
`
