import React from 'react'
import './styles.scss';
import { navigateTo } from 'gatsby-link'
import Img from "gatsby-image"

import {
    Button
} from '../../components'

import { StaticQuery, graphql } from "gatsby"

class HomeServices extends React.Component {
    render() {

        let mobile = false
        try {
            mobile = window.innerWidth <= 991 ? true : false
        } catch (e) {}

        return (
            <StaticQuery
                query={graphql`
                    query {
                        allImageSharp(filter: {fluid: {originalName: {in: [
                        "irrigation-residentiel.jpg",
                        "irrigation-commercial.jpg",
                        "irrigation-reglement-arrosage.jpg",
                        "irrigation-agriculture-urbaine.jpg",
                        "irrigation-plans.png",
                        "irrigation-municipal.jpg",
                        "irrigation-toit-vert.jpg",
                        ]}}}) {
                        edges {
                            node {
                                fluid {
                                    originalName
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                        }
                    }
                `}
                render={data => {
                    const allImages = data.allImageSharp.edges;
                    const Residentiel = allImages.find(e => e.node.fluid.originalName === 'irrigation-residentiel.jpg')?.node.fluid
                    const Commercial = allImages.find(e => e.node.fluid.originalName === 'irrigation-commercial.jpg')?.node.fluid
                    const Reglements = allImages.find(e => e.node.fluid.originalName === 'irrigation-reglement-arrosage.jpg')?.node.fluid
                    const AgricultureUrbaine = allImages.find(e => e.node.fluid.originalName === 'irrigation-agriculture-urbaine.jpg')?.node.fluid
                    const Plans = allImages.find(e => e.node.fluid.originalName === 'irrigation-plans.png')?.node.fluid
                    const Municipale = allImages.find(e => e.node.fluid.originalName === 'irrigation-municipal.jpg')?.node.fluid
                    const ToitVert = allImages.find(e => e.node.fluid.originalName === 'irrigation-toit-vert.jpg')?.node.fluid
                    return (
                        <>
                            {!mobile && <div className="container">
                                <h1 className="text-center section-header">Installation de système d'irrigation et d'arrosage automatique</h1>
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-6 bloc-parent">
                                        <div className="bloc-content">
                                            <Img fluid={Residentiel} alt="Irrigation Résidentielle" className="bloc-image" />
                                            <div className="bloc-text">
                                                <h2>Irrigation Résidentielle</h2>
                                                <a className="btn-round btn btn-primary btn-lg" onClick={() => navigateTo('/services/irrigation-residentielle')} href="/services/irrigation-residentielle">En savoir plus</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 bloc-parent">
                                        <div className="row bloc-parent bloc-small-parent" style={{ marginBottom: 25 }}>
                                            <div className="col-xs-6 col-md-6">
                                                <div className="bloc-content">
                                                    <Img fluid={Commercial} alt="Irrigation Commerciale" className="bloc-image" />
                                                    <div className="bloc-text">
                                                        <h2>Irrigation Commerciale</h2>
                                                        <a className="btn-round btn btn-primary btn-lg" onClick={() => navigateTo('/services/irrigation-commerciale')} href="/services/irrigation-commerciale">En savoir plus</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xs-6 col-md-6">
                                                <div className="bloc-content">
                                                    <Img fluid={Municipale} alt="Irrigation Municipale" className="bloc-image" />
                                                    <div className="bloc-text">
                                                        <h2>Irrigation Municipale</h2>
                                                        <a className="btn-round btn btn-primary btn-lg" onClick={() => navigateTo('/services/irrigation-municipale')} href="/services/irrigation-municipale">En savoir plus</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row bloc-parent bloc-small-parent">
                                            <div className="col-xs-12 col-md-12">
                                                <div className="bloc-content">
                                                    <Img fluid={ToitVert} alt="Irrigation de Toîts vert" className="bloc-image" style={{ height: 328 }} />
                                                    <div className="bloc-text">
                                                        <h2>Irrigation de Toîts vert</h2>
                                                        <a className="btn-round btn btn-primary btn-lg" onClick={() => navigateTo('/services/irrigation-toits-vert')} href="/services/irrigation-toits-vert">En savoir plus</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row' style={{marginTop: 25, minHeight: 235 }}>
                                    <div className='col-xs-12 col-sm-12 col-md-6 bloc-parent'>
                                        <div className="bloc-content">
                                            <Img fluid={AgricultureUrbaine} alt="Irrigation en Agriculture urbaine" className="bloc-image" style={{ height: 328 }} />
                                            <div className="bloc-text">
                                                <h2>Irrigation en Agriculture urbaine</h2>
                                                <a className="btn-round btn btn-primary btn-lg" onClick={() => navigateTo('/services/agriculture-urbaine')} href="/services/agriculture-urbaine">En savoir plus</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-xs-12 col-sm-12 col-md-6 bloc-parent'>
                                        <div className="bloc-content">
                                            <Img fluid={Plans} alt="Irrigation plans" className="bloc-image" style={{ height: 328 }} />
                                            <div className="bloc-text">
                                                <h2>Conception de plans d'aménagement extérieur</h2>
                                                <a className="btn-round btn btn-primary btn-lg" onClick={() => navigateTo('/services/conception-de-plans')} href="/services/conception-de-plans">En savoir plus</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row' style={{marginTop: 25, minHeight: 235 }}>
                                    <div className='col-xs-12 col-sm-12 col-md-12 bloc-parent'>
                                        <div className="bloc-content">
                                            <Img fluid={Reglements} alt="Irrigation règlements arrosage municipaux" className="bloc-image" style={{height: 300}} />
                                            <div className="bloc-text">
                                                <h2>Consulter vos règlements municipaux</h2>
                                                <a className="btn-round btn btn-primary btn-lg" onClick={() => navigateTo('/documentations/reglements')} href="/documentations/reglements">En savoir plus</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}

                            {mobile && <div>
                                <h1 className="text-center section-header">Installation de système d'irrigation et d'arrosage automatique</h1>
                                <div className="bloc-parent">
                                    <div className="bloc-content">
                                        <Img fluid={Residentiel} alt="Irrigation Résidentielle" className="bloc-image" />
                                        <div className="bloc-text">
                                            <h2>Irrigation Résidentielle</h2>
                                            <a className="btn-round btn btn-primary btn-lg" onClick={() => navigateTo('/services/irrigation-residentielle')} href="/services/irrigation-residentielle">En savoir plus</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="bloc-parent">
                                    <div className="bloc-content">
                                        <Img fluid={Commercial} alt="Irrigation Commerciale" className="bloc-image" />
                                        <div className="bloc-text">
                                            <h2>Irrigation Commerciale</h2>
                                            <a className="btn-round btn btn-primary btn-lg" onClick={() => navigateTo('/services/irrigation-commerciale')} href="/services/irrigation-commerciale">En savoir plus</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="bloc-parent">
                                    <div className="bloc-content">
                                        <Img fluid={Municipale} alt="Irrigation Municipale" className="bloc-image" />
                                        <div className="bloc-text">
                                            <h2>Irrigation Municipale</h2>
                                            <a className="btn-round btn btn-primary btn-lg" onClick={() => navigateTo('/services/irrigation-municipale')} href="/services/irrigation-municipale">En savoir plus</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="bloc-parent">
                                    <div className="bloc-content">
                                        <Img fluid={ToitVert} alt="Irrigation de Toîts vert" className="bloc-image" />
                                        <div className="bloc-text">
                                            <h2>Irrigation de Toîts vert</h2>
                                            <a className="btn-round btn btn-primary btn-lg" onClick={() => navigateTo('/services/irrigation-toits-vert')} href="/services/irrigation-toits-vert">En savoir plus</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="bloc-parent">
                                    <div className="bloc-content">
                                        <Img fluid={AgricultureUrbaine} alt="Irrigation en Agriculture urbaine" className="bloc-image" />
                                        <div className="bloc-text">
                                            <h2>Irrigation en Agriculture urbaine</h2>
                                            <a className="btn-round btn btn-primary btn-lg" onClick={() => navigateTo('/services/agriculture-urbaine')} href="/services/agriculture-urbaine">En savoir plus</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="bloc-parent">
                                    <div className="bloc-content">
                                        <Img fluid={Plans} alt="Irrigation plans" className="bloc-image" />
                                        <div className="bloc-text">
                                            <h2>Conception de plans d'aménagement extérieur</h2>
                                            <a className="btn-round btn btn-primary btn-lg" onClick={() => navigateTo('/services/conception-de-plans')} href="/services/conception-de-plans">En savoir plus</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="bloc-parent">
                                    <div className="bloc-content">
                                        <Img fluid={Reglements} alt="Irrigation règlements arrosage municipaux" className="bloc-image" />
                                        <div className="bloc-text">
                                            <h2>Consulter vos règlements municipaux</h2>
                                            <a className="btn-round btn btn-primary btn-lg" onClick={() => navigateTo('/documentations/reglements')} href="/documentations/reglements">En savoir plus</a>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        </>
                    )
                }}
            />
        );
    };
}

export default HomeServices;
